import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";

const ThanksPage = () => (
  <Layout>
    <Helmet>
      <script>{`gtag('event', 'conversion', {'send_to': 'AW-941382018/b-rZCInq-owDEIKz8cAD'});`}</script>
    </Helmet>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>I'll be in touch!</p>
          <p>
            Please note that our emails may land in your junk folder. We will
            always reply, so please check there just in case!
          </p>
          <p>Mob: 07557876824</p>
        </div>
      </div>
    </section>
  </Layout>
);

export default ThanksPage;
